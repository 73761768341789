// import i18n from '@/language/i18n'
import auth from '@/middleware/auth'

export default [
	{
		path: '/roles/:page?/:id?',
		name: 'Roles',
		icon: 'roles',
		meta: {
			title: 'menu.roles'
		},
		beforeEnter: auth,
		component: () => import('@/pages/Roles')
	},
	{
		path: '/users/:page?/:id?',
		name: 'Users',
		icon: 'users',
		meta: {
			title: 'menu.users'
		},
		beforeEnter: auth,
		component: () => import('@/pages/Users')
	},
	{
		path: '/commands/:page?/:id?',
		name: 'Commands',
		icon: 'terminal',
		meta: {
			title: 'menu.commands'
		},
		beforeEnter: auth,
		component: () => import('@/pages/Commands')
	},
	{
		path: '/groups/:page?/:id?',
		name: 'Groups',
		icon: 'folders',
		meta: {
			title: 'menu.groups'
		},
		beforeEnter: auth,
		component: () => import('@/pages/Groups')
	},
	{
		path: '/projects/:page?/:id?',
		name: 'Projects',
		icon: 'projects',
		meta: {
			title: 'menu.projects'
		},
		beforeEnter: auth,
		component: () => import('@/pages/Projects')
	},
	/* auto-gerado */
	{
		path: '/settings',
		name: 'Settings',
		icon: 'settings',
		meta: {
			title: 'menu.settings'
		},
		beforeEnter: auth,
		component: () => import('@/pages/Settings')
	}
]
